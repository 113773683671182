import React from 'react'
import Img from "../images/nyc@2x.jpg"

import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <div className="index-combo">
      <div className="index-combo-child">
        <div className="index-combo-img">
          <img src={Img} alt="At the top of world looking for the future" />
        </div>
      </div>
      <div className="index-combo-child">
        <h1>Hey, fellow visitor</h1>
        <p>
          I am Carlos Assis; also known as Ewerton Carlos Assis. I'm a Software Engineer and a Computer Scientist.
          I hold an MBA in Artificial Intelligence &amp; Machine Learning from FIAP (Brazil) and
          a BSc degree in Computer Science from Universidade Federal de Goias (Brazil).
        </p>
        <p>
          You may <a href="mailto:earaujoassis@gmail.com">say hi</a> through e-mail &mdash; my PGP public key is available at <a href="//keybase.io/earaujoassis">Keybase</a>;
          the fingerprint is <code>8D49 1A56 76F9 8BE8 8D2C DA73 42F2 F1D2 C37C 914A</code>.
          I'm also on <a href="https://github.com/earaujoassis">GitHub</a>, <a href="https://www.linkedin.com/in/earaujoassis">LinkedIn</a>,
          and <a href="//twitter.com/earaujoassis">Twitter</a>.
        </p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
